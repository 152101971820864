import styles from "../assets/styles/News.module.css"
import Item from "./Item"

const ItemList = ({ objetos, collectionName, displayFields }) => {
   return (
      <div className={styles.newsGrid}>
         {objetos.length > 0 ? (
            objetos.map((objeto) => (
               <Item 
                  key={objeto.id} 
                  objeto={objeto} 
                  displayFields={displayFields}
                  collectionName={collectionName} // Asegúrate de pasar collectionName
               />
            ))
         ) : (
            <p>No items disponibles</p>
         )}
      </div>
   )
}

export default ItemList;