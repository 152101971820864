import React, { useState } from 'react';
import { db, auth } from '../firebase/config';
import { addDoc, collection } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

export function NewAgency() {
    const [property, setProperty] = useState('');
    const [image, setImage] = useState('');
    const [cost, setCost] = useState('');
    const [city, setCity] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Obtener el usuario actualmente autenticado
        const user = auth.currentUser;

        if (!user || !user.uid) {
            console.error('Usuario no autenticado o UID no disponible.');
            return;
        }

        try {
            // Guardar los datos en Firestore usando addDoc
            const docRef = await addDoc(collection(db, 'Agencias'), {
                propiedad: property,
                imagen: image,
                costo: cost,
                ciudad: city,
                userId: user.uid, // Agregar el ID de usuario como campo adicional
            });

            console.log('Documento creado correctamente:', docRef.id);

            // Limpiar los campos del formulario después de enviar los datos
            setProperty('');
            setImage('');
            setCost('');
            setCity('');

            // Mostrar mensaje de éxito o redireccionar a otra página
            navigate(-1);  // Redireccionar a la página de listado de agencias

        } catch (error) {
            console.error('Error al guardar en Firestore:', error);
            // Manejar el error apropiadamente, mostrar un mensaje de error, etc.
        }
    };

    const navigate = useNavigate();

    return (
        <div className="container mt-4">
            <h2>Nueva Agencia</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="property">Propiedad</label>
                    <input
                        type="text"
                        className="form-control"
                        id="property"
                        value={property}
                        onChange={(e) => setProperty(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="image">Imagen</label>
                    <input
                        type="text"
                        className="form-control"
                        id="image"
                        value={image}
                        onChange={(e) => setImage(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="cost">Costo</label>
                    <input
                        type="text"
                        className="form-control"
                        id="cost"
                        value={cost}
                        onChange={(e) => setCost(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="city">Ciudad</label>
                    <input
                        type="text"
                        className="form-control"
                        id="city"
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        required
                    />
                </div>
                <button type="submit" className="btn btn-primary">
                    Guardar
                </button>
            </form>
        </div>
    );
}
