const estadosMunicipios = {
    Aguascalientes: ['Aguascalientes', 'Asientos', 'Calvillo', 'Cosío', 'Jesús María', 'Pabellón de Arteaga', 'Rincón de Romos', 'San José de Gracia', 'Tepezalá', 'El Llano'],
    BajaCalifornia: ['Ensenada', 'Mexicali', 'Tecate', 'Tijuana', 'Playas de Rosarito'],
    BajaCaliforniaSur: ['La Paz', 'Los Cabos', 'Comondú', 'Loreto', 'Mulegé'],
    Campeche: ['Campeche', 'Calkiní', 'Carmen', 'Champotón', 'Hecelchakán', 'Hopelchén', 'Palizada', 'Tenabo', 'Escárcega'],
    Chiapas: ['Tuxtla Gutiérrez', 'Tapachula', 'San Cristóbal de las Casas', 'Comitán de Domínguez', 'Chiapa de Corzo', 'Villaflores', 'Tonalá', 'Palenque', 'Ocosingo'],
    Chihuahua: ['Chihuahua', 'Juárez', 'Cuauhtémoc', 'Delicias', 'Parral', 'Nuevo Casas Grandes', 'Camargo', 'Meoqui', 'Jiménez'],
    Coahuila: ['Saltillo', 'Torreón', 'Monclova', 'Piedras Negras', 'Acuña', 'Matamoros', 'San Pedro', 'Francisco I. Madero', 'Frontera'],
    Colima: ['Colima', 'Manzanillo', 'Tecomán', 'Villa de Álvarez'],
    Durango: ['Durango', 'Gómez Palacio', 'Lerdo', 'Ciudad Lerdo', 'Torreón', 'Santiago Papasquiaro', 'Guadalupe Victoria', 'Nuevo Ideal'],
    Guanajuato: ['Guanajuato', 'León', 'Irapuato', 'Salamanca', 'Celaya', 'Dolores Hidalgo', 'San Miguel de Allende', 'Silao', 'Valle de Santiago'],
    Guerrero: ['Chilpancingo', 'Acapulco', 'Iguala', 'Taxco', 'Zihuatanejo', 'Chilapa', 'Tlapa', 'Ometepec'],
    Hidalgo: ['Pachuca', 'Tulancingo', 'Tula', 'Ixmiquilpan', 'Huejutla', 'Actopan', 'Tepeji del Río', 'Apan', 'Tizayuca'],
    Jalisco: ['Guadalajara', 'Zapopan', 'Tlaquepaque', 'Tonalá', 'Puerto Vallarta', 'Tepatitlán', 'El Salto', 'Lagos de Moreno', 'Ocotlán'],
    México: ['Toluca', 'Naucalpan', 'Tlalnepantla', 'Ecatepec', 'Nezahualcóyotl', 'Tlalnepantla', 'Cuautitlán Izcalli', 'Chimalhuacán', 'Naucalpan'],
    Michoacán: ['Morelia', 'Uruapan', 'Lázaro Cárdenas', 'Zamora', 'Pátzcuaro', 'Apatzingán', 'Zitácuaro', 'Sahuayo', 'La Piedad'],
    Morelos: ['Cuernavaca', 'Cuautla', 'Jiutepec', 'Temixco', 'Yautepec', 'Xochitepec', 'Tepoztlán', 'Puente de Ixtla', 'Emiliano Zapata'],
    Nayarit: ['Tepic', 'Bahía de Banderas', 'Santiago Ixcuintla', 'Compostela', 'Acaponeta', 'Tecuala', 'Ixtlán del Río', 'San Blas', 'Rosamorada'],
    NuevoLeón: ['Monterrey', 'Guadalupe', 'San Nicolás de los Garza', 'Escobedo', 'Santa Catarina', 'San Pedro Garza García', 'Apodaca', 'Juárez', 'General Escobedo'],
    Oaxaca: ['Oaxaca', 'Salina Cruz', 'Juchitán de Zaragoza', 'Huajuapan de León', 'Tuxtepec', 'San Juan Bautista Tuxtepec', 'Miahuatlán de Porfirio Díaz', 'Santiago Pinotepa Nacional', 'Santo Domingo Tehuantepec'],
    Puebla: ['Puebla', 'Tehuacán', 'San Andrés Cholula', 'Cholula', 'Atlixco', 'Izúcar de Matamoros', 'Teziutlán', 'Huauchinango', 'Acatlán de Osorio'],
    Querétaro: ['Querétaro', 'San Juan del Río', 'Corregidora', 'El Marqués', 'Jalpan de Serra', 'Pedro Escobedo', 'Amealco de Bonfil', 'Ezequiel Montes', 'Huimilpan'],
    QuintanaRoo: ['Cancún', 'Playa del Carmen', 'Chetumal', 'Cozumel', 'Isla Mujeres', 'Tulum', 'Bacalar', 'Felipe Carrillo Puerto', 'José María Morelos'],
    SanLuisPotosí: ['San Luis Potosí', 'Soledad de Graciano Sánchez', 'Matehuala', 'Ciudad Valles', 'Rioverde', 'Tamazunchale', 'Ébano', 'Cárdenas', 'Salinas de Hidalgo'],
    Sinaloa: ['Culiacán', 'Mazatlán', 'Los Mochis', 'Guasave', 'Mochis', 'El Fuerte', 'Navolato', 'Escuinapa', 'Salvador Alvarado'],
    Sonora: ['Hermosillo', 'Ciudad Obregón', 'Nogales', 'San Luis Río Colorado', 'Guaymas', 'Empalme', 'Navojoa', 'Caborca', 'Puerto Peñasco'],
    Tabasco: ['Villahermosa', 'Cárdenas', 'Comalcalco', 'Tenosique', 'Macuspana', 'Centro', 'Jalpa de Méndez', 'Paraíso', 'Balancán'],
    Tamaulipas: ['Reynosa', 'Matamoros', 'Nuevo Laredo', 'Tampico', 'Ciudad Victoria', 'Ciudad Madero', 'Altamira', 'Mante', 'Valle Hermoso'],
    Tlaxcala: ['Tlaxcala', 'Apizaco', 'Zacatelco', 'Huamantla', 'Chiautempan', 'Tlaxco', 'Santa Ana Chiautempan', 'Contla de Juan Cuamatzi', 'Panotla'],
    Veracruz: ['Veracruz', 'Xalapa', 'Coatzacoalcos', 'Minatitlán', 'Poza Rica', 'Córdoba', 'Papantla', 'Tuxpan', 'Cosoleacaque'],
    Yucatán: ['Mérida', 'Kanasín', 'Progreso', 'Tizimín', 'Umán', 'Valladolid', 'Tekax', 'Izamal', 'Motul'],
    Zacatecas: ['Zacatecas', 'Fresnillo', 'Jerez', 'Rio Grande', 'Guadalupe', 'Sombrerete', 'Loreto', 'Valparaíso', 'Villa de Cos'],
  };

export default estadosMunicipios;
