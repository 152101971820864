import React from 'react';
import { useTranslation } from 'react-i18next';
import { auth } from '../firebase/config';
import { toast } from 'react-toastify';

export function EmailVerification (){
  const { t } = useTranslation('EmailVerification');

  const handleCheckVerification = async () => {
    const user = auth.currentUser;
    if (user) {
      await user.reload();
      if (user.emailVerified) {
        toast.success(t('emailVerified'), {
          position: "top-center",
        });
        window.location.href = "/login";
      } else {
        toast.error(t('emailNotVerified'), {
          position: "top-center",
        });
      }
    }
  };

  return (
    <div>
      <h2>{t('VERIFICA TU CORREO')}</h2>
      <p>{t('Entra a tu Correo Electrónico y visita el enlace, una vez hecho presiona el botón para verificar tu cuenta')}</p>
      <button onClick={handleCheckVerification}>{t('Correo Verificado')}</button>
    </div>
  );
};
