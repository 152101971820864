import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../assets/styles/Register.module.css';
import { auth, db, googleProvider } from "../firebase/config";
import { setDoc, doc } from "firebase/firestore";
import { toast } from "react-toastify";
import { createUserWithEmailAndPassword, updateProfile, sendEmailVerification, signInWithPopup } from "firebase/auth";

export function RegisterForm() {
  const { t } = useTranslation('Register');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [errors, setErrors] = useState({});
  const [authError, setAuthError] = useState(""); // Mantener esto

  const [termsAccepted, setTermsAccepted] = useState(false); // Asegúrate de que esté en false por defecto

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  const validate = () => {
    let errors = {};
    if (!name) {
      errors.name = t('errors.nameRequired');
    }
    if (!email) {
      errors.email = t('errors.emailRequired');
    } else if (!validateEmail(email)) {
      errors.email = t('errors.emailInvalid');
    }
    if (!password) {
      errors.password = t('errors.passwordRequired');
    } else if (password.length < 6) {
      errors.password = t('errors.passwordShort');
    }
    if (!termsAccepted) {
      errors.terms = t('errors.termsRequired'); // Error si los términos no están aceptados
    }
    return errors;
  }

  const handleRegister = async (e) => {
    e.preventDefault();
    setErrors({});
    setAuthError(""); // Mantener esto
    const validationErrors = validate();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      if (user) {
        await updateProfile(user, {
          displayName: name,
          photoURL: "",
        });
  
        await sendEmailVerification(user);
  
        await setDoc(doc(db, "Users", user.uid), {
          email: user.email,
          Name: name,
          photo: "",
          isAdmin: false,
          phone: phone || "", // Agregar el teléfono si se proporciona
          subscription: false, // Agregar el campo subscription
          typeSubscription: '', // Agregar el campo typeSubscription
        });
  
        toast.success(t('verificationEmailSent'), {
          position: "top-center",
        });
        window.location.href = "/EmailVerification";
      }
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setAuthError(t('errors.emailInUse')); // Mantener esto
      } else {
        setAuthError(t('errors.registrationError')); // Mantener esto
      }
    }
  };

  const handleGoogleRegister = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;

      // Registro en Firestore
      await setDoc(doc(db, "Users", user.uid), {
        email: user.email,
        Name: user.displayName || "",
        photo: user.photoURL || "",
        isAdmin: false,
        phone: phone || "", // Agregar el teléfono si se proporciona
        subscription: false, // Agregar el campo subscription
        typeSubscription: '', // Agregar el campo typeSubscription
      });

      toast.success(t('googleRegistrationSuccess'), {
        position: "top-center",
      });
      window.location.href = "/";
    } catch (error) {
      console.error('Error during Google registration:', error);
      toast.error(t('errors.registrationError'), {
        position: "top-center",
      });
    }
  };

  return (
    <div className={styles.registerContainer}>
      <div className={styles.registerBox}>
        <div className={styles.titulo}>
          <h2>{t('title')}</h2>
        </div>
        <form onSubmit={handleRegister}>
          <input
            type="text"
            placeholder={t('namePlaceholder')}
            className={styles.inputField}
            onChange={(e) => {
              setName(e.target.value);
              setErrors({ ...errors, name: "" });
            }}
          />
          {errors.name && <p className={styles.error}>{errors.name}</p>}
          <input
            type="email"
            placeholder={t('emailPlaceholder')}
            className={styles.inputField}
            onChange={(e) => {
              setEmail(e.target.value);
              setErrors({ ...errors, email: "" });
            }}
          />
          {errors.email && <p className={styles.error}>{errors.email}</p>}
          <input
            type="password"
            placeholder={t('passwordPlaceholder')}
            className={styles.inputField}
            onChange={(e) => {
              setPassword(e.target.value);
              setErrors({ ...errors, password: "" });
            }}
          />
          {errors.password && <p className={styles.error}>{errors.password}</p>}
          <input
            type="text"
            placeholder={t('phonePlaceholder')}
            className={styles.inputField}
            onChange={(e) => {
              setPhone(e.target.value);
            }}
          />
          {errors.terms && <p className={styles.error}>{errors.terms}</p>}
          {authError && <p className={styles.error}>{authError}</p>} {/* Mostrar el error aquí */}
          <div className={styles.aceptoCondiciones}>
            <input 
              type="checkbox" 
              id="termsCheckbox" 
              checked={termsAccepted}
              className={styles.checkkk}
              onChange={(e) => setTermsAccepted(e.target.checked)} 
            />
            <label htmlFor="termsCheckbox">{t('acceptTerms')}</label>
          </div>
          <div className={styles.footerR}>
            <button type='submit' className={styles.registerButton}>{t('registerButton')}</button>
            <button type='button' className={styles.termsButton} onClick={() => window.open('/Terms')}>{t('termsLink')}</button>
          </div>
        </form>
        <div className={styles.googleRegisterContainer}>
          <button onClick={handleGoogleRegister} className={styles.googleRegisterButton}>
            {t('Registrate con Google')}
          </button>
        </div>
      </div>
    </div>
  );
}