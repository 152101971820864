import { useState, useEffect } from 'react';
import { auth, db } from '../firebase/config';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

export const useAuth = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth,async (currentUser) => {
            if (currentUser) {
                setIsLoggedIn(true);
                setUser(currentUser)

                // Obtener el documento del usuario desde Firestore
                const userDoc = await getDoc(doc(db, "Users", currentUser.uid));
                if (userDoc.exists()) {
                    const userData = userDoc.data();
                    setIsAdmin(userData.isAdmin);
                }
                
            } else {
                setIsLoggedIn(false);
                setUser(null);
                setIsAdmin(false);
            }
        });
        return () => unsubscribe();
    }, []);

    const handleSignOut = () => {
        signOut(auth).then(() => {
            window.location.href = "/";
            // Sign-out successful.
        }).catch((error) => {
            console.error("Error signing out: ", error);
        });
    };

    return { isLoggedIn, isAdmin, user, handleSignOut };
};