import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import estadosMunicipios from './Location';
import styles from '../assets/styles/Filtering.module.css';

const Filtering = ({ handleFilter, handleReset }) => {
  const { t } = useTranslation('filtering');
  const [category, setCategory] = useState('');
  const [price, setPrice] = useState('');
  const [state, setState] = useState('');
  const [city, setCity] = useState('');
  const [municipalities, setMunicipalities] = useState([]);

  useEffect(() => {
    if (state) {
      setMunicipalities(estadosMunicipios[state] || []);
    } else {
      setMunicipalities([]);
    }
  }, [state]);

  useEffect(() => {
    handleFilter(category, price, state, city);
  }, [category, price, state, city, handleFilter]);

  const resetFilters = () => {
    setCategory('');
    setPrice('');
    setState('');
    setCity('');
    handleFilter('', '', '', '');
    if (handleReset) handleReset();
  };

  return (
    <div className={styles.filterBar}>
      <select
        className={styles.filterSelect}
        value={category}
        onChange={(e) => setCategory(e.target.value)}
      >
        <option value="">{t('filter.category')}</option>
        <option value="Terrenos">{t('filter.lands')}</option>
        <option value="Residencias">{t('filter.residences')}</option>
        <option value="Rentas">{t('filter.rentals')}</option>
      </select>

      <input
        type="number"
        className={styles.filterSelect}
        placeholder={t('filter.maxPrice')}
        value={price}
        onChange={(e) => setPrice(e.target.value)}
      />

      <select
        className={styles.filterSelect}
        value={state}
        onChange={(e) => setState(e.target.value)}
      >
        <option value="">{t('filter.state')}</option>
        {Object.keys(estadosMunicipios).map((estado) => (
          <option key={estado} value={estado}>
            {estado}
          </option>
        ))}
      </select>

      <select
        className={styles.filterSelect}
        value={city}
        onChange={(e) => setCity(e.target.value)}
      >
        <option value="">{t('filter.municipality')}</option>
        {municipalities.map((municipio) => (
          <option key={municipio} value={municipio}>
            {municipio}
          </option>
        ))}
      </select>

      <button className={styles.filterButton} onClick={resetFilters}>
        {t('filter.clearFilters')}
      </button>
    </div>
  );
};

export default Filtering;
