import React, { useRef } from 'react';
import emailjs from 'emailjs-com';
import styles from '../assets/styles/Contact.module.css';
import { Navbar } from '../components/navbar1';
import { Footer } from '../components/Footer';
import { useTranslation } from 'react-i18next';

export function Contact() {
    const { t } = useTranslation('Contact');
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_zepmoa7', 'template_dkuxlwd', form.current, 'nH6qu4CK3isTQlqZn')
            .then((result) => {
                console.log(result.text);
                alert(t('emailSuccess'));
            }, (error) => {
                console.log(error.text);
                alert(t('emailError'));
            });
    };

    return (
        <div>
            <div className={styles.headerContainer}>
                <div className={styles.navBa}>
                    <Navbar />
                </div>
                <div className={styles.overlay}></div>
                <div className={styles.headerContent}>
                    <h1>{t('header.title')}</h1>
                </div>
            </div>
            <div className={styles.banner}>
                <h3>{t('header.helpTitle')}</h3>
            </div>
            <div className={styles.formContainer}>
                <form ref={form} onSubmit={sendEmail} className={styles.formulario}>
                    <div className={styles.formRow}>
                        <div className={styles.inputGroup}>
                            <label htmlFor="nombre">{t('form.name')}</label>
                            <input type="text" id="nombre" name="nombre" required className={styles.inputText} placeholder={t('form.placeholderName')} />
                        </div>
                        <div className={styles.inputGroup}>
                            <label htmlFor="apellidos">{t('form.surname')}</label>
                            <input type="text" id="apellidos" name="apellidos" required className={styles.inputText} placeholder={t('form.placeholderSurname')} />
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div className={styles.inputGroup}>
                            <label htmlFor="correo">{t('form.email')}</label>
                            <input type="email" id="correo" name="correo" required className={styles.inputText} placeholder={t('form.placeholderEmail')} />
                        </div>
                        <div className={styles.inputGroup}>
                            <label htmlFor="telefonos">{t('form.phone')}</label>
                            <input type="text" id="telefonos" name="telefonos" required className={styles.inputText} placeholder={t('form.placeholderPhone')} />
                        </div>
                    </div>
                    <div className={styles.formRow}>
                        <div className={`${styles.inputGroup} ${styles.fullWidth}`}>
                            <label htmlFor="comentario">{t('form.comment')}</label>
                            <textarea id="comentario" name="comentario" rows="5" required className={styles.textarea}></textarea>
                        </div>
                    </div>
                    <button type="submit" className={styles.submitButton}>{t('form.submitButton')}</button>
                </form>
            </div>

            <div className={styles.infExtra}>
                <div className={styles.direccion}>
                    <div className={styles.headerTa}>
                        <h3>Direccion</h3>
                    </div>
                    <div className={styles.texto}>
                        <p>CALLE VENUSTIANO CARRANZA #43 INTERIOR 3, COLONIA CENTRO C. P. 76850, AMEALCO DE BONFIL, QUERÉTARO.</p>
                    </div>
                </div>
                <div className={styles.telefonos}>
                    <div className={styles.telc}>
                        <div>
                            <h3>Telefono fijo</h3>
                        </div>
                        <div>                            
                            <p>448-278-05-40</p>
                        </div>
                    </div>
                    <div className={styles.telc}>
                        <div>
                            <h3>Telefono celular</h3>
                        </div>
                        <div>                            
                            <p>+52 1 427 277 7080</p>
                        </div>
                    </div>
                </div>
            </div>


            <Footer />
        </div>
    );
}
