import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//IMPORTS INGLES
import HomeEN from '../src/locales/en/Home.json';
import AboutEN from '../src/locales/en/About.json';
import afiliateEN from '../src/locales/en/afiliate.json';
import ContactEN from '../src/locales/en/Contact.json';
import filteringEN from '../src/locales/en/filtering.json';
import LoginEN from '../src/locales/en/Login.json';
import navbarEN from '../src/locales/en/navbar.json';
import ProfilesEN from '../src/locales/en/Profiles.json';
import ProjectsEN from '../src/locales/en/Projects.json';
import PublicateEN from '../src/locales/en/Publicate.json';
import RegisterEN from '../src/locales/en/Register.json';
import FooterEN from '../src/locales/en/footer.json';
import NewsEN from '../src/locales/en/news.json';
/*
import DetailsEN from '../src/locales/en/Details.json';
import newAgencyEN from '../src/locales/en/newAgency.json';
*/

//IMPORTS ESPAÑOL

import HomeES from '../src/locales/es/Home.json';
import AboutES from '../src/locales/es/About.json';
import afiliateES from '../src/locales/es/afiliate.json';
import ContactES from '../src/locales/es/Contact.json';
import filteringES from '../src/locales/es/filtering.json';
import LoginES from '../src/locales/es/Login.json';
import navbarES from '../src/locales/es/navbar.json';
import ProfilesES from '../src/locales/es/Profiles.json';
import ProjectsES from '../src/locales/es/Projects.json';
import PublicateES from '../src/locales/es/Publicate.json';
import RegisterES from '../src/locales/es/Register.json';
import FooterES from '../src/locales/es/footer.json';
import NewsES from '../src/locales/es/news.json';
/*
import DetailsES from '../src/locales/es/Details.json';
import newAgencyES from '../src/locales/es/newAgency.json';
*/



// Configuración de los recursos de traducción
const resources = {
  en: {
    Home: HomeEN,
    About: AboutEN,
    afiliate: afiliateEN,
    Contact: ContactEN,
    filtering: filteringEN,
    Login: LoginEN,
    navbar: navbarEN,
    Profiles: ProfilesEN,
    Projects: ProjectsEN,
    Publicate: PublicateEN,
    Register: RegisterEN,
    footer: FooterEN,
    news: NewsEN
    /*
    Details: DetailsEN,
    newAgency: newAgencyEN,*/
  },
  es: { 
    Home: HomeES,
    About: AboutES,
    afiliate: afiliateES,
    Contact: ContactES,
    filtering: filteringES,
    Login: LoginES,
    navbar :navbarES,
    Profiles: ProfilesES,
    Projects: ProjectsES,
    Publicate: PublicateES,
    Register: RegisterES,
    footer: FooterES,
    news: NewsES
    /*
    Details: DetailsES,
    newAgency: newAgencyES,
    */
  },
};

// Inicializar i18next
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'es', // Idioma por defecto a español
    fallbackLng: 'es', // Idioma de reserva
    interpolation: {
      escapeValue: false, // React ya hace el escape de valores por defecto
    },
  });

export default i18n;
