import styles from '../assets/styles/Footer.module.css'
import { FaFacebookF, FaLinkedinIn, FaWhatsapp } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

export function Footer() {
    const { t } = useTranslation('footer');
    const whatsappNumber = '+5214272777080';

    return (
        <footer className={styles.footer}>
            <div className={styles.footerTop}>
                <div className={styles.footerLogo}>
                    <a href='/'><img src='logo.png' alt="Logo" width="150" /></a>
                </div>
                <div className={styles.footerSocial}>
                    <a href="https://www.facebook.com/sheksaconstrucciones"><FaFacebookF /></a>
                    <a href="https://www.linkedin.com/in/erick-tripp-8534b7250/?original_referer=https%3A%2F%2Fwww%2Egoogle%2Ecom%2F&originalSubdomain=mx"><FaLinkedinIn /></a>
                    <a href={`https://wa.me/${whatsappNumber}`}><FaWhatsapp /></a>
                </div>
                <div className={styles.footerInfo}>
                    <div>
                        <a href='/about'><p>{t('about_us')}</p></a>
                        <a href='/projects'><p>{t('projects')}</p></a>
                        <a href='/contact'><p>{t('contact')}</p></a>
                    </div>
                </div>
            </div>
            <div className={styles.footerBottom}>
                <p>&copy; 2024 SHEKSA Construcciones, S.A DE C.V | <a href="#">{t('privacy_policy')}</a></p>
            </div>
        </footer>
    )
}

export default Footer;
