import React from 'react';
import styles from '../assets/styles/NewsInfo.module.css';
import { Footer } from '../components/Footer';

export function NewsInfo() {
  return (
    <div>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.imageContainer}>
            <img src="contact.jpg" alt="Venta de Terrenos el Trebol" />
          </div>
          <div className={styles.textContainer}>
            <h1>Venta de terrenos el trebol</h1>
            <p>
              Vive en armonía con tecnologías ecológicas como iluminación solar, huerto orgánico y área de compostaje.
              ¡No te pierdas esta experiencia inolvidable!
              Ubicados en Querétaro, <span className={styles.highlight}>#PuntaTenango</span> es el mejor desarrollo eco-residencial de <span className={styles.highlight}>@SheksaConstrucciones</span>.
              Confirma tu visita al <span className={styles.highlight}>427 277 7080</span> y descubre por qué somos
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
