import React, { useEffect, useState } from "react";
import { auth, db, storage } from "../firebase/config";
import { doc, getDoc, updateDoc, deleteDoc, collection, query, where, getDocs } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import styles from "../assets/styles/Profiles.module.css";
import { Navbar } from '../components/navbar1';
import pedirObjetos from '../components/pedirObjetos';
import ItemList from '../components/ItemList';
import Publicate from '../components/Publications';
import { useAuth } from '../components/statusUser';
import EditProfileModal from '../components/EditProfileModal';
import { useTranslation } from 'react-i18next';

export function Profile() {
  const { t } = useTranslation('Profiles');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [profileModalIsOpen, setProfileModalIsOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [newName, setNewName] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newPhone, setNewPhone] = useState("");
  const [newPhoto, setNewPhoto] = useState(null);
  const [objetos, setObjetos] = useState([]);
  const [updateKey, setUpdateKey] = useState(0); // Nuevo estado para forzar actualización
  const [postCount, setPostCount] = useState(0);
  const [canAddPost, setCanAddPost] = useState(false);
  const { isLoggedIn, user } = useAuth();

  const openProfileModal = () => {
    setProfileModalIsOpen(true);
    document.body.style.overflow = 'hidden'; // Deshabilita el scroll
  };

  const openModal = () => {
    setModalIsOpen(true);
    document.body.style.overflow = 'hidden'; // Deshabilita el scroll
  };

  const closeProfileModal = () => {
    setProfileModalIsOpen(false);
    document.body.style.overflow = 'auto'; // Habilita el scroll
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'auto'; // Habilita el scroll
  };

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const docRef = doc(db, "Users", user.uid);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserDetails(data);
          setNewName(data.Name);
          setNewEmail(data.email);
          setNewPhone(data.phone || "");

          // Verificar la validez de la suscripción
          const subscriptionStartDate = data.subscriptionStartDate?.toDate(); // Asegúrate de que la fecha esté en formato Date
          const currentDate = new Date();
          let isSubscriptionValid = false;

          if (subscriptionStartDate) {
            const oneMonthLater = new Date(subscriptionStartDate);
            oneMonthLater.setMonth(subscriptionStartDate.getMonth() + 1);

            if (currentDate <= oneMonthLater) {
              isSubscriptionValid = true;
            } else {
              // La suscripción ha expirado
              await updateDoc(docRef, { subscription: false });
            }
          }

          // Actualiza el estado de la suscripción
          setUserDetails(prevDetails => ({
            ...prevDetails,
            subscriptionStatus: isSubscriptionValid
          }));

          // Contar publicaciones del usuario
          const postsRef = collection(db, 'Agencies');
          const q = query(postsRef, where('userId', '==', user.uid));
          const querySnapshot = await getDocs(q);
          const posts = querySnapshot.docs.map(doc => doc.data());
          setObjetos(posts);
          setPostCount(posts.length);

          // Verificar el tipo de suscripción
          const subscriptionType = data.typeSubscription;
          let maxPosts = 0;
          switch (subscriptionType) {
            case 'Suscripción BASIC':
              maxPosts = 2;
              break;
            case 'Suscripción PLUS':
              maxPosts = 100;
              break;
            default:
              maxPosts = 0;
          }
          console.log(`Subscription Type: ${subscriptionType}, Max Posts: ${maxPosts}, Current Posts: ${postCount}`);
          setCanAddPost(isSubscriptionValid && postCount < maxPosts);

        } else {
          console.log("User data not found");
        }
      }
    };


    fetchUserData();
  }, [user, updateKey, postCount]);


  const handleEditProfile = () => {
    openProfileModal();
  };

  const handleSaveProfile = async () => {
    try {
      const user = auth.currentUser;
      const userDocRef = doc(db, "Users", user.uid);

      await updateDoc(userDocRef, {
        Name: newName,
        email: newEmail,
        phone: newPhone,
      });

      if (newPhoto) {
        const photoRef = ref(storage, `profilePhotos/${user.uid}`);
        await uploadBytes(photoRef, newPhoto);
        const photoURL = await getDownloadURL(photoRef);

        await updateDoc(userDocRef, {
          photo: photoURL,
        });
      }

      closeProfileModal();
      console.log("Profile updated successfully!");
      setUpdateKey((prevKey) => prevKey + 1); // Actualizar el updateKey para forzar actualización
    } catch (error) {
      console.error(t('profile.errorMessages.profileUpdate'), error);
    }
  };

  const handleRenewSubscription = () => {
    // Aquí puedes redirigir al usuario a una página de renovación o manejar la lógica de renovación
    // Por ejemplo:
    window.location.href = "/Publicate";
  };

  const handleDeleteAccount = async () => {
    try {
      const user = auth.currentUser;
      const userDocRef = doc(db, "Users", user.uid);
      await deleteDoc(userDocRef);
      await user.delete();
      window.location.href = "/";
      console.log("User account deleted successfully!");
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const displayFields = {
    image: true,
    title: true,
    description: false,
    city: true,
    state: false,
    direction: false,
    category: false,
    price: true,
    userName: false,
    additionalImages: true
  };

  const collectionName = "Agencies";

  return (
    <div className={styles.aaa}>
      <Navbar />
      <div className={styles.container}>
        {userDetails ? (
          <>
            <div className={styles.profileHeader}>
              <div className={styles.imgHeader}>
                <img
                  src={userDetails.photo}
                  className={styles.profileImg}
                  alt="User Profile"
                />
              </div>
              <div className={styles.infHeader}>
                <h3 className={styles.profileName}>{userDetails.Name}</h3>
                <p className={styles.profileEmail}>{userDetails.email}</p>
                {userDetails.phone && <p className={styles.profilePhone}>{userDetails.phone}</p>}
                <div className={styles.subscriptionStatus}>
                  <div className={styles.subscriptionStatus}>
                    {userDetails.subscriptionStatus ? (  
                    <div className={styles.contenedorStatus}>
                      <div className={styles.indicator}></div>
                      <p>{t('profile.statusActivo')}</p>
                    </div>
                    ) : (
                      <>
                        <div className={styles.contenedorStatus}>
                          <div className={styles.indicatorRed}></div>
                          <div className={styles.Status}>
                            <p>{t('profile.statusExpirado')}</p>
                          </div>
                        </div>
                        <button onClick={handleRenewSubscription} className={styles.btnStatus}>
                          {t('profile.renovar')}
                        </button>
                      </>
                    )}
                  </div>
                  
                </div>
              </div>
            </div>
            <div className={styles.btnHeader}>
              <button className={`${styles.btn}`} onClick={handleEditProfile}>
                {t('profile.editProfile')}
              </button>
              {!userDetails.phone && (
                <button className={`${styles.btnPhone}`} onClick={handleEditProfile}>
                  {t('profile.addPhone')}
                </button>
              )}
              <button className={styles.btnDelete} onClick={handleDeleteAccount}>
                {t('profile.deleteAccount')}
              </button>
            </div>
          </>
        ) : (
          <p>{t('profile.loading')}</p>
        )}
      </div>
      <div className={styles.Prop}>
        <div className={styles.linea}></div>

        <div className={styles.textP}>
          <h2>{t('profile.myProperties')}</h2>
        </div>

        <div className={styles.abajo}>
          {isLoggedIn && (
            <div className={styles.abajoBtn}>
              {canAddPost && (
                <button onClick={openModal} className={styles.btn}>
                  {t('profile.addNewProject')}
                </button>
              )}
            </div>
          )}

          <ItemList objetos={objetos} displayFields={displayFields} collectionName={collectionName} />
          <Publicate
            collectionName="Agencies"
            fields={[
              { name: 'title', label: 'Título', type: 'text', required: true },
              {
                name: 'category', label: 'Categoría', type: 'select', required: true, options: [
                  { value: '', label: 'Selecciona opción', disabled: true },
                  { value: 'Terrenos', label: 'Terrenos' },
                  { value: 'Residencias', label: 'Residencias' },
                  { value: 'Rentas', label: 'Rentas' }
                ]
              },
              { name: 'price', label: 'Precio', type: 'number', required: true },
              { name: 'state', label: 'Estado', type: 'select', required: true },
              { name: 'city', label: 'Municipio', type: 'select', required: true },
              { name: 'description', label: 'Descripción', type: 'text', required: true },
            ]}
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
          />
        </div>
      </div>
      <EditProfileModal
        isOpen={profileModalIsOpen}
        onRequestClose={closeProfileModal}
        userDetails={userDetails}
        newName={newName}
        setNewName={setNewName}
        newEmail={newEmail}
        setNewEmail={setNewEmail}
        newPhone={newPhone}
        setNewPhone={setNewPhone}
        newPhoto={newPhoto}
        setNewPhoto={setNewPhoto}
        handleSaveProfile={handleSaveProfile}
      />
    </div>
  );
}