import { Navbar } from '../components/navbar1';
import { Footer } from '../components/Footer';
import styles from '../assets/styles/Affiliate.module.css';
import Filtering from '../components/Filtering';
import { useAuth } from '../components/statusUser';
import { useEffect, useState } from 'react';
import pedirObjetos from '../components/pedirObjetos';
import ItemList from '../components/ItemList';
import Publicate from '../components/Publications';
import { db } from '../firebase/config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

export function AffiliateAgencies() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [objetos, setObjetos] = useState([]);
  const [filteredAgencies, setFilteredAgencies] = useState([]);
  const { isLoggedIn } = useAuth();
  const { t } = useTranslation('afiliate');

  const openModal = () => {
    setModalIsOpen(true);
    document.body.style.overflow = 'hidden'; // Deshabilita el scroll
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'auto'; // Habilita el scroll
  };

  useEffect(() => {
    const collectionName = "Agencies";
    pedirObjetos(collectionName)
      .then((res) => {
        setObjetos(res);
        setFilteredAgencies(res); // Inicialmente muestra todos los proyectos
      })
      .catch((err) => {
        setObjetos([]);
        setFilteredAgencies([]);
      })
  }, [])

  const handleFilter = async (category, price, state, city) => {
    const projectCollection = collection(db, 'Agencies');
    let q = query(projectCollection);

    if (category) {
      q = query(q, where('category', '==', category));
    }
    if (price) {
      q = query(q, where('price', '<=', parseFloat(price)));
    }
    if (state) {
      q = query(q, where('state', '==', state));
    }
    if (city) {
      q = query(q, where('city', '==', city));
    }

    const snapshot = await getDocs(q);
    const Agencies = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setFilteredAgencies(Agencies);
  };

  const handleReset = () => {
    setFilteredAgencies(objetos);
  };

  const displayFields = {
    image: true,
    title: true,
    description: false,
    city: true,
    state: false,
    direction: false,
    category: false,
    price: true,
    userName: true,
    additionalImages: true
  }

  return (
    <div>
      <div className={styles.headerContainer}>
        <div className={styles.navBa}>
          <Navbar />
        </div>
        <div className={styles.overlay}></div>
        <div className={styles.headerContent}>
          <h1>{t('header.title')}</h1>
        </div>
      </div>
      <Filtering handleFilter={handleFilter} handleReset={handleReset} />

      <ItemList objetos={filteredAgencies} displayFields={displayFields} collectionName="Agencies" />
      <Publicate
        collectionName="Agencies"
        fields={[
          { name: 'title', label: t('publicate.fields.title'), type: 'text', required: true },
          {
            name: 'category', label: t('publicate.fields.category.label'), type: 'select', required: true, options: t('publicate.fields.category.options', { returnObjects: true })
          },
          { name: 'price', label: t('publicate.fields.price'), type: 'number', required: true },
          { name: 'state', label: t('publicate.fields.state'), type: 'select', required: true },
          { name: 'city', label: t('publicate.fields.city'), type: 'select', required: true },
          { name: 'description', label: t('publicate.fields.description'), type: 'text', required: true },
        ]}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
      />
      <Footer />
    </div>
  )
}
