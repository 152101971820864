import { Navbar } from '../components/navbar1';
import { Footer } from '../components/Footer';
import { collection, query, where, getDocs } from 'firebase/firestore';
import styles from '../assets/styles/projects.module.css';
import  Filtering  from '../components/Filtering';
import { useEffect, useState } from 'react';
import pedirObjetos from '../components/pedirObjetos';
import ItemList from '../components/ItemList';
import Publicate from '../components/Publications';
import { useAuth } from '../components/statusUser';
import { db } from '../firebase/config';
import { useTranslation } from 'react-i18next';

export function Projects() {
  const { t } = useTranslation('Projects');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [objetos, setObjetos] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const { isAdmin } = useAuth();

  const openModal = () => {
    setModalIsOpen(true);
    document.body.style.overflow = 'hidden'; // Deshabilita el scroll
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'auto'; // Habilita el scroll
  };

  useEffect(() => {
    const collectionName = "Projects";
    pedirObjetos(collectionName)
      .then((res) => {
        setObjetos(res);
        setFilteredProjects(res); // Inicialmente muestra todos los proyectos
      })
      .catch((err) => {
        setObjetos([]);
        setFilteredProjects([]);
      });
  }, []);

  const handleFilter = async (category, price, state, city) => {
    const projectCollection = collection(db, 'Projects');
    let q = query(projectCollection);

    if (category) {
      q = query(q, where('category', '==', category));
    }
    if (price) {
      q = query(q, where('price', '<=', parseFloat(price)));
    }
    if (state) {
      q = query(q, where('state', '==', state));
    }
    if (city) {
      q = query(q, where('city', '==', city));
    }

    const snapshot = await getDocs(q);
    const projects = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setFilteredProjects(projects);
  };

  const handleReset = () => {
    setFilteredProjects(objetos);
  };

  const displayFields = {
    image: true,
    title: true,
    description: false,
    city: false,
    state: false,
    direction: false,
    category: true,
    price: true,
    userName: false,
    additionalImages: true
  };

  return (
    <div>
      <div className={styles.headerContainer}>
        <div className={styles.navBa}>
          <Navbar />
        </div>
        <div className={styles.overlay}></div>
        <div className={styles.headerContent}>
          <h1>{t('projects.title')}</h1>
        </div>
      </div>
      <Filtering handleFilter={handleFilter} handleReset={handleReset} />
      {isAdmin && (
        <button onClick={openModal} className="btn btn-primary">
          {t('projects.addNewProject')}
        </button>
      )}
      <ItemList objetos={filteredProjects} displayFields={displayFields} collectionName="Projects" />
      <Publicate
        collectionName="Projects"
        fields={[
          { name: 'title', label: t('projects.fields.title'), type: 'text', required: true },
          { name: 'category', label: t('projects.fields.category'), type: 'select', required: true, options: [
              { value: '', label: 'Selecciona opción', disabled: true },
              { value: 'Terrenos', label: 'Terrenos' },
              { value: 'Residencias', label: 'Residencias' },
              { value: 'Rentas', label: 'Rentas' }
            ]
          },
          { name: 'price', label: t('projects.fields.price'), type: 'number', required: true },
          { name: 'state', label: t('projects.fields.state'), type: 'select', required: true },
          { name: 'city', label: t('projects.fields.city'), type: 'select', required: true },
          { name: 'description', label: t('projects.fields.description'), type: 'text', required: true },
        ]}
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
      />
      <Footer />
    </div>
  );
}
