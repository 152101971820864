import React, { useEffect, useState } from 'react';
import styles from '../assets/styles/Affiliate.module.css';
import { useAuth } from '../components/statusUser';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase/config';
import { collection, getDocs, query, where } from 'firebase/firestore';

export function OwnAgencys() {
    const { isLoggedIn, user } = useAuth();
    const navigate = useNavigate();
    const [agencies, setAgencies] = useState([]);

    useEffect(() => {
        const fetchAgencies = async () => {
            try {
                if (!user) return; // Si no hay usuario logeado, no se hace la consulta

                // Consultar las agencias donde userId sea igual al ID del usuario actual
                const q = query(collection(db, 'Agencias'), where('userId', '==', user.uid));
                const querySnapshot = await getDocs(q);

                const agenciesData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setAgencies(agenciesData);
            } catch (error) {
                console.error('Error fetching agencies:', error);
            }
        };

        fetchAgencies();
    }, [user]); // Dependencia para que se vuelva a consultar cuando cambia el usuario

    const handleAddButtonClick = () => {
        navigate('/NewAgency');
    };

    return (
        <div>
            <div className={styles.btn}>
                {isLoggedIn && (
                    <button className={styles.addButton} onClick={handleAddButtonClick}>
                        Agregar
                    </button>
                )}
            </div>

            <div className={styles.listings}>
                {agencies.map((agency, index) => (
                    <div key={index} className={styles.listing}>
                        <img src={agency.imagen} alt={agency.propiedad} />
                        <h3>Propiedad: {agency.propiedad}</h3>
                        <p>Costo: {agency.costo}</p>
                        <p>Ciudad: {agency.ciudad}</p>
                        <a href={`/Agencia/${agency.id}`}>Ver Detalles</a>
                    </div>
                ))}
            </div>
        </div>
    );
}
