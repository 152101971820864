import React from 'react';
import styles from '../assets/styles/infoExtra.module.css';

export function InfoExtra({ title, subtitle, description, image }) {
  return (
    <div className={styles.containerAI}>
      <div className={styles.card}>
        <div className={styles.cardImage}>
          <img src={image} alt={title} className={styles.image} />
        </div>
        <div className={styles.cardContent}>
          <div className={styles.titulo}>
            <h3 className={styles.title}>{title}</h3>
          </div>
          <div className={styles.subtitulo}>
            <h4 className={styles.subtitle}>{subtitle}</h4>
          </div>
          <div className={styles.desc}>
            <p className={styles.description}>{description}</p>
          </div>
        </div>
      </div>
    </div>
  );
}