import React from 'react';
import ReactDOM from 'react-dom/client';
import { Rutas } from './components/Rutas';
import Modal from 'react-modal';
import './i18n';

Modal.setAppElement('#root');

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <>
      <Rutas/>
  </>
);
