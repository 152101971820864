import React, { useState } from 'react';
import { sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { auth, googleProvider } from "../firebase/config"; // Asegúrate de importar googleProvider
import { useNavigate } from "react-router-dom";
import styles from '../assets/styles/loginn.module.css';
import { toast } from 'react-toastify';
import googleLogo from '../assets/images/google.png';
import { useTranslation } from 'react-i18next';

function ForgotPassword({ closeModal }) {
    const { t } = useTranslation('Login');
    const handleSubmit = async (e) => {
        e.preventDefault();
        const emailVal = e.target.email.value;
        try {
            await sendPasswordResetEmail(auth, emailVal);
            toast.success(t('forgotPassword.emailSent'), {
                position: "top-center",
            });
            closeModal();
        } catch (err) {
            toast.error(t('forgotPassword.error'), {
                position: "top-center",
            });
            console.error(err.code);
        }
    };

    return (
        <div className={styles.modalContent}>
            <h1>{t('forgotPassword.title')}</h1>
            <form onSubmit={handleSubmit}>
                <input name="email" placeholder={t('forgotPassword.c')} /><br /><br />
                <button type="submit">{t('forgotPassword.submitButton')}</button>
            </form>
        </div>
    );
}

function Modal({ show, closeModal, children }) {
    if (!show) return null;

    return (
        <div className={styles.modal}>
            <div className={styles.modalOverlay} onClick={closeModal}></div>
            <div className={styles.modalBox}>
                <button className={styles.modalCloseButton} onClick={closeModal}>X</button>
                {children}
            </div>
        </div>
    );
}

export function Login() {
    const [emaillog, setEmaillog] = useState("");
    const [passwordlog, setPasswordlog] = useState("");
    const [errors, setErrors] = useState({});
    const [authError, setAuthError] = useState("");
    const [showModal, setShowModal] = useState(false);
    const { t } = useTranslation('Login');
    const navigate = useNavigate();

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validate = () => {
        let errors = {};
        if (!emaillog) {
            errors.emaillog = t('login.errorMessages.emailRequired');
        } else if (!validateEmail(emaillog)) {
            errors.emaillog = t('login.errorMessages.emailInvalid');
        }
        if (!passwordlog) {
            errors.passwordlog = t('login.errorMessages.passwordRequired');
        } else if (passwordlog.length < 6) {
            errors.passwordlog = t('login.errorMessages.passwordLength');
        }
        return errors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setAuthError("");
        const validationErrors = validate();
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
            return;
        }
        try {
            const userCredential = await signInWithEmailAndPassword(auth, emaillog, passwordlog);
            const user = userCredential.user;
            if (user.emailVerified) {
                toast.success(t('login.success'), {
                    position: "top-center",
                });
                navigate("/");
            } else {
                toast.error(t('login.verifyEmail'), {
                    position: "top-center",
                });
                navigate("/EmailVerification");
            }
        } catch (error) {
            console.error(error.message);
            setAuthError(t('login.authError'));
        }
    };

    const handleGoogleSignIn = async () => {
        try {
            const result = await signInWithPopup(auth, googleProvider);
            const user = result.user;
            if (user.emailVerified) {
                toast.success(t('login.success'), {
                    position: "top-center",
                });
                navigate("/");
            } else {
                toast.error(t('login.verifyEmail'), {
                    position: "top-center",
                });
                navigate("/EmailVerification");
            }
        } catch (error) {
            console.error('Error during Google login:', error);
            toast.error(t('loginError'), {
                position: "top-center",
            });
        }
    };

    const handleReset = () => {
        setShowModal(true);
    };

    return (
        <div className={styles.loginContainer}>
            <div className={styles.loginBox}>
                <h2>{t('login.title')}</h2>
                <form onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder={t('login.emailPlaceholder')}
                        className={styles.inputField}
                        value={emaillog}
                        onChange={(e) => {
                            setEmaillog(e.target.value);
                            setErrors({ ...errors, emaillog: "" });
                        }}
                    />
                    {errors.emaillog && <p className={styles.error}>{errors.emaillog}</p>}
                    <input
                        type="password"
                        placeholder={t('login.passwordPlaceholder')}
                        className={styles.inputField}
                        value={passwordlog}
                        onChange={(e) => {
                            setPasswordlog(e.target.value);
                            setErrors({ ...errors, passwordlog: "" });
                        }}
                    />
                    {errors.passwordlog && <p className={styles.error}>{errors.passwordlog}</p>}
                    {authError && <p className={styles.error}>{authError}</p>}
                    <button type='submit' className={styles.loginButton}>{t('login.loginButton')}</button>
                </form>

                <div className={styles.googleLoginContainer}>
                    <button onClick={handleGoogleSignIn} className={styles.googleLoginButton}>
                        <img src={googleLogo} alt="Google Logo" className={styles.googleLogo} />
                    </button>
                </div>

                <div className={styles.footerLinks}>
                    <button onClick={handleReset}>{t('login.forgotPassword')}</button>
                    <button><a href='/Register'>{t('login.createAccount')}</a></button>
                </div>
            </div>

            <Modal show={showModal} closeModal={() => setShowModal(false)}>
                <ForgotPassword closeModal={() => setShowModal(false)} />
            </Modal>
        </div>
    );
}
