import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../assets/styles/Toolbar.module.css';
import mexicoFlag from '../assets/images/mexico.png';
import usFlag from '../assets/images/us.png';

export function Toolbar() {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className={styles.toolbarContainer}>
      <div className={styles.toolbar}>
        <button className={styles.toolbarItem} onClick={() => changeLanguage('es')}>
          <img src={mexicoFlag} alt="ESP" className={styles.icon} />
          <span>ESP</span>
        </button>
        <button className={styles.toolbarItem} onClick={() => changeLanguage('en')}>
          <img src={usFlag} alt="ENG" className={styles.icon} />
          <span>ENG</span>
        </button>
      </div>
    </div>
  );
}
