// src/components/CardInfHome.js
import React from 'react';
import styles from '../assets/styles/cardInfHome.module.css';

export function CardInfHome({ title, subtitle, description, image }) {
  return (
    <div className={styles.card}>
      <div className={styles.cardImage}>
        <img src={image} alt={title} />
      </div>
      <div className={styles.cardContent}>
        <h3>{title}</h3>
        <div className={styles.cardHeader}>
          <h4>{subtitle}</h4>
          <p>{description}</p>
        </div> 
      </div>
    </div>
  );
}
