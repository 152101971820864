import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from './statusUser';
import { auth, db } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import styles from '../assets/styles/navbar1.module.css';

export function Navbar() {
    const { t } = useTranslation('navbar');
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language;
    const [isOpen, setIsOpen] = useState(false);
    const { isLoggedIn, handleSignOut } = useAuth();
    const [profileImage, setProfileImage] = useState('');
    const [showProfileOptions, setShowProfileOptions] = useState(false);
    const profileMenuRef = useRef(null);
    const navRef = useRef(null);
    const [logoError, setLogoError] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const fetchProfileImage = async (userId) => {
        const docRef = doc(db, "Users", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            setProfileImage(docSnap.data().photo);
        } else {
            console.log("No such document!");
        }
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                fetchProfileImage(user.uid);
            } else {
                setProfileImage('');
            }
        });
        return () => unsubscribe();
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) ||
                (navRef.current && !navRef.current.contains(event.target))
            ) {
                setShowProfileOptions(false);
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleProfileOptions = () => {
        setShowProfileOptions(!showProfileOptions);
    };

    return (
        <nav className={styles.nav} ref={navRef}>
            <div className={styles.container}>
                {/* Logo Section */}
                <div className={styles.logo}>
                    <Link to="/" onClick={closeMenu}>
                        <img src='/LOGO-SHEKSA1.png' alt={t('navbar.logoAlt')} onError={() => setLogoError(true)} />
                        {logoError && <p style={{ color: 'red' }}>Logo no encontrado</p>}
                    </Link>
                </div>

                <button className={styles.hamburger} onClick={toggleMenu}>
                    ☰
                </button>

                {/* Menu Section */}
                <div className={styles.menu}>
                    <ul className={`${styles.ul} ${isOpen ? styles.open : ''}`}>
                        <li>
                            <Link to="/about" onClick={closeMenu}>{t('navbar.about')}</Link>
                        </li>
                        <li>
                            <Link to="/projects" onClick={closeMenu}>{t('navbar.projects')}</Link>
                        </li>
                        <li>
                            <Link to="/contact" onClick={closeMenu}>{t('navbar.contact')}</Link>
                        </li>
                        <li>
                            <Link to="/news" onClick={closeMenu}>{t('navbar.news')}</Link>
                        </li>
                        <li className={`${styles.affiliate} ${currentLanguage === 'es' ? styles.affiliateEs : styles.affiliateEn}`}>
                            <Link to="/AffiliateAgencies" onClick={closeMenu}>{t('navbar.affiliate')}</Link>
                        </li>

                        {!isLoggedIn ? (
                            <>
                                <li className={styles.ocu}>
                                    <Link to="/login" className={styles.login} onClick={closeMenu}>{t('navbar.login')}</Link>
                                </li>
                                <li className={styles.ocu}>
                                    <Link to="/Publicate" className={styles.joinNow} onClick={closeMenu}>{t('navbar.joinNow')}</Link>
                                </li>
                            </>
                        ) : (
                            <>
                                <li className={styles.ocu}>
                                    <Link to="/profile" className={styles.login} onClick={closeMenu}>{t('navbar.profile')}</Link>
                                </li>
                                <li className={styles.ocu}>
                                    <Link to="/Publicate" className={styles.joinNow} onClick={closeMenu}>{t('navbar.joinNow')}</Link>
                                </li>
                                <li className={styles.ocu}>
                                    <button onClick={() => { handleSignOut(); closeMenu(); }} className={`${styles.gogo} ${styles.buttonU}`}>{t('navbar.signOut')}</button>
                                </li>
                            </>
                        )}
                    </ul>
                </div>

                {/* Profile/Login/JoinNow Section */}
                <div className={styles.auth}>
                    {isLoggedIn ? (
                        <div className={styles.profileMenu} ref={profileMenuRef}>
                            <img 
                                src={profileImage || 'about.jpg'} 
                                alt={t('navbar.profileAlt')} 
                                className={styles.profileImage} 
                                onClick={toggleProfileOptions}
                            />
                            {showProfileOptions && (
                                <div className={styles.profileOptions}>
                                    <Link to="/profile" className={`${styles.gogo} ${styles['logged-in']}`} onClick={closeMenu}>{t('navbar.profile')}</Link>
                                    <Link to="/Publicate" className={`${styles.gogo} ${styles['logged-in']}`} onClick={closeMenu}>{t('navbar.joinNow')}</Link>
                                    <button onClick={() => { handleSignOut(); closeMenu(); }} className={`${styles.gogo} ${styles.buttonU}`}>{t('navbar.signOut')}</button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <>
                            <Link to="/login" className={styles.login} onClick={closeMenu}>{t('navbar.login')}</Link>
                            <Link to="/Publicate" className={styles.joinNow} onClick={closeMenu}>{t('navbar.joinNow')}</Link>
                        </>
                    )}
                </div>
            </div>
        </nav>
    );
}
