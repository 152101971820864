import Card from "./Card";

const Item = ({ objeto, collectionName, displayFields }) => {
   return (
      <Card
         id = {objeto.id}
         collectionName={collectionName}
         image = {objeto.image}
         title = {objeto.title}
         description={objeto.description}
         city={objeto.city}
         state={objeto.state}
         direction={objeto.direction}
         category={objeto.category}
         price={objeto.price}
         userName={objeto.userName}
         userId={objeto.userId}
         additionalImages={objeto.additionalImages || []}
         displayFields={displayFields}
      />
   );
};

export default Item;