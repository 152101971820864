import Modal from 'react-modal';
import React, { useState, useEffect } from 'react';
import { updateDoc, deleteDoc, doc, getDoc } from 'firebase/firestore';
import { Link } from "react-router-dom";
import styles from "../assets/styles/Cards.module.css";
import { db, storage } from '../firebase/config';
import { useAuth } from './statusUser';
import { ref, deleteObject, uploadBytes, getDownloadURL } from 'firebase/storage';
import estadosMunicipios from './Location'; 

const Card = ({ id, collectionName, image, title, description, price, city, state, direction, category, displayFields, userId, additionalImages = [] }) => {
  const { user, isAdmin } = useAuth(); // Usar el hook useAuth para obtener isAdmin

  const allowedFields = {
    News: ['title', 'description', 'image'],
    Projects: ['image', 'additionalImages', 'title', 'category','price', 'description'],
    Agencies: ['title','image', 'price', 'city','state', 'additionalImages',]
    // Agrega más colecciones y sus campos permitidos según sea necesario
  };

  const [formData, setFormData] = useState({
    image: image || null,
    title: title || '',
    description: description || '',
    price: price || '',
    city: city || '',
    state: state || '',
    direction: direction || '',
    category: category || '',
    additionalImages: additionalImages || [],
  });

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [newAdditionalImages, setNewAdditionalImages] = useState([]);
  const [userName, setUserName] = useState('');
  const [municipios, setMunicipios] = useState([]);

  useEffect(() => {
    if (formData.state) {
      setMunicipios(estadosMunicipios[formData.state] || []);
    } else {
      setMunicipios([]);
    }
  }, [formData.state]);

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const userDocRef = doc(db, 'Users', userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserName(userDocSnap.data().Name);
        } else {
          console.error('Usuario no encontrado');
        }
      } catch (error) {
        console.error('Error al obtener el nombre del usuario: ', error);
      }
    };

    fetchUserName();
  }, [userId]);

  if (!id || !collectionName) {
    console.error('Error: id or collectionName is missing.');
    return null;
  }

  const handleChange = (e) => {
    if (e.target.name === 'additionalImages') {
      setNewAdditionalImages([...newAdditionalImages, ...Array.from(e.target.files)]);
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files ? e.target.files[0] : e.target.value,
      });
    }
  };

  const handleUpdate = async () => {
    try {
      const docRef = doc(db, collectionName, id); // Utiliza el nombre de la colección dinámicamente

      let updatedData = {};

      if (formData.image instanceof File) {
        if (image) {
          const imageRef = ref(storage, image);
          await deleteObject(imageRef);
        }

        const storageRef = ref(storage, `${collectionName}/${formData.image.name}`);
        await uploadBytes(storageRef, formData.image);
        const downloadURL = await getDownloadURL(storageRef);
        updatedData.image = downloadURL;
      }

      const additionalImagesUrls = [...formData.additionalImages];
      for (const file of newAdditionalImages) {
        const storageRef = ref(storage, `${collectionName}/additional/${file.name}`);
        await uploadBytes(storageRef, file);
        const url = await getDownloadURL(storageRef);
        additionalImagesUrls.push(url);
      }
      updatedData.additionalImages = additionalImagesUrls;

      const fields = allowedFields[collectionName];
      fields.forEach((field) => {
        if (formData[field] !== undefined) {
          switch (field) {
            case 'title':
              if (formData.title !== title) updatedData.title = formData.title;
              break;
            case 'description':
              if (formData.description !== description) updatedData.description = formData.description;
              break;
            case 'price':
              if (formData.price !== price) updatedData.price = formData.price;
              break;
            case 'city':
              if (formData.city !== city) updatedData.city = formData.city;
              break;
            case 'state':
              if (formData.state !== state) updatedData.state = formData.state;
              break;
            case 'direction':
              if (formData.direction !== direction) updatedData.direction = formData.direction;
              break;
            case 'category':
              if (formData.category !== category) updatedData.category = formData.category;
              break;
            default:
              break;
          }
        }
      });

      if (Object.keys(updatedData).length > 0) {
        await updateDoc(docRef, updatedData);
      }

      closeModal();
      window.location.reload();
    } catch (error) {
      console.error('Error al actualizar el documento: ', error);
    }
  };

  const handleDelete = async () => {
    try {
      // Eliminar imagen principal
      if (image) {
        const imageRef = ref(storage, image);
        await deleteObject(imageRef);
      }

      // Eliminar imágenes adicionales
      for (const imgUrl of formData.additionalImages) {
        const imgRef = ref(storage, imgUrl);
        await deleteObject(imgRef);
      }

      const docRef = doc(db, collectionName, id);
      await deleteDoc(docRef);

      closeConfirmDelete();
      window.location.reload();
    } catch (error) {
      console.error('Error al eliminar el documento: ', error);
    }
  };

  const handleRemoveAdditionalImage = async (index) => {
    try {
      // Eliminar la imagen adicional del Firebase Storage
      const imageUrl = formData.additionalImages[index];
      const imgRef = ref(storage, imageUrl);
      await deleteObject(imgRef);

      // Eliminar la imagen del array formData.additionalImages
      const updatedAdditionalImages = [...formData.additionalImages];
      updatedAdditionalImages.splice(index, 1);

      // Actualizar el estado con las imágenes restantes
      setFormData({ ...formData, additionalImages: updatedAdditionalImages });

      // Realizar la actualización en Firestore
      const docRef = doc(db, collectionName, id);
      await updateDoc(docRef, { additionalImages: updatedAdditionalImages });

    } catch (error) {
      console.error('Error al eliminar la imagen adicional: ', error);
    }
  };


  const openModal = () => {
    setModalIsOpen(true);
    document.body.style.overflow = 'hidden'; // Deshabilita el scroll
  };

  const closeModal = () => {
    setModalIsOpen(false);
    document.body.style.overflow = 'auto'; // Habilita el scroll
  };

  const openConfirmDelete = () => {
    setConfirmDelete(true);
    document.body.style.overflow = 'hidden'; // Deshabilita el scroll
  };

  const closeConfirmDelete = () => {
    setConfirmDelete(false);
    document.body.style.overflow = 'auto'; // Habilita el scroll
  };

  const editOrDelete = user && (user.uid === userId || isAdmin);

  return (
    <div key={id}>
      <div className={styles.card}>
        {displayFields.image && <img className={styles.fixedSizeImage} src={image} alt={title} />}
        {displayFields.title && <h3>{title}</h3>}
        <div className={styles.containerBot}>
          {displayFields.description && <p>{description}</p>}
          {displayFields.city && <p>{city}</p>}
          {displayFields.state && <p>{state}</p>}
          {displayFields.direction && <p>{direction}</p>}
          {displayFields.category && <p>{category}</p>}
          {displayFields.price && <p>{price}</p>}
          {displayFields.userName && (
            <p>
              <Link to={`/UserProfile/${userId}`}>{userName}</Link>
            </p>
          )}
          <Link to={`/${collectionName}/${id}`} className={styles.link}>
            Ver Detalles
          </Link>

        </div>

        {editOrDelete && (
          <>
            <div className={styles.btnCard}>
              <button onClick={openModal}>Editar</button>
              <button onClick={openConfirmDelete}>Eliminar</button>
            </div>
          </>
        )}

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel={`Editar ${collectionName}`}
          className={styles.modalContent}
          overlayClassName={styles.modalOverlay}
        >
          <h2>Editar {collectionName}</h2>
          <form>
            {allowedFields[collectionName].includes('title') && (
              <div className={styles.contentI}>
                <label>Título:</label>
                <input type="text" name="title" value={formData.title} onChange={handleChange} className={styles.formControl} />
              </div>
            )}
            {allowedFields[collectionName].includes('description') && (
              <div className={styles.contentI}>
                <label>Descripción:</label>
                <textarea name="description" value={formData.description} onChange={handleChange} className={styles.formControl} />
              </div>
            )}
            {allowedFields[collectionName].includes('price') && (
              <div className={styles.contentI}>
                <label>Precio:</label>
                <input type="number" name="price" value={formData.price} onChange={handleChange} className={styles.formControl} />
              </div>
            )}
            {allowedFields[collectionName].includes('city') && (
               <div>
               <label>Estado:</label>
               <select name="state" value={formData.state} onChange={handleChange}>
                 <option value="" disabled>Selecciona un estado</option>
                 {Object.keys(estadosMunicipios).map((estado) => (
                   <option key={estado} value={estado}>{estado}</option>
                 ))}
               </select>
             </div>
            )}
            {allowedFields[collectionName].includes('state') && (
              <div>
              <label>Ciudad:</label>
              <select name="city" value={formData.city} onChange={handleChange}>
                <option value="" disabled>Selecciona una ciudad</option>
                {municipios.map((municipio) => (
                  <option key={municipio} value={municipio}>{municipio}</option>
                ))}
              </select>
            </div>
            )}
            {allowedFields[collectionName].includes('direction') && (
              <div className={styles.contentI}>
                <label>Dirección:</label>
                <input type="text" name="direction" value={formData.direction} onChange={handleChange} className={styles.formControl} />
              </div>
            )}
            {allowedFields[collectionName].includes('category') && (
              <div className={styles.contentI}>
                <label>Categoría:</label>
                <select name="category" value={formData.category} onChange={handleChange} className={styles.formControl}>
                  <option value="" disabled>Selecciona una opcion</option>
                  <option value="Terrenos">Terrenos</option>
                  <option value="Residencias">Residencias</option>
                  <option value="Rentas">Rentas</option>
                </select>
              </div>
            )}
            {allowedFields[collectionName].includes('image') && (
              <div className={styles.contentI}>
                <label>Imagen:</label>
                <input type="file" name="image" onChange={handleChange} className={styles.formControl} />
              </div>
            )}
            {allowedFields[collectionName].includes('additionalImages') && (
              <div className={styles.contentI}>
                <label>Imágenes Adicionales:</label>
                <div className={styles.additionalImagesContainer}>
                  {formData.additionalImages.map((url, index) => (
                    <div key={index} className={styles.additionalImageWrapper}>
                      <img src={url} alt={`Adicional ${index + 1}`} className={styles.additionalImage} />
                      <button type="button" onClick={() => handleRemoveAdditionalImage(index)}>Eliminar</button>
                    </div>
                  ))}
                </div>
                <input type="file" name="additionalImages" onChange={handleChange} multiple className={styles.formControl} />
              </div>
            )}
            <button type="button" onClick={handleUpdate} className={styles.btn}>Guardar Cambios</button>
          </form>
        </Modal>

        <Modal
          isOpen={confirmDelete}
          onRequestClose={closeConfirmDelete}
          contentLabel="Confirmar Eliminación"
          className={styles.modalContent1}
          overlayClassName={styles.modalOverlay}
        >
          <div className={styles.eliminacionM}>
            <div>
              <h2>Confirmar Eliminación</h2>
              <p>¿Estás seguro de que deseas eliminar este elemento?</p>
            </div>
            <div className={styles.eliminacionMBtn}>
              <button type="button" onClick={handleDelete} className={styles.btn1}>Sí, eliminar</button>
              <button type="button" onClick={closeConfirmDelete} className={styles.btn1}>Cancelar</button>
            </div>
          </div>
        </Modal>

      </div>
    </div>
  );
};

export default Card;
