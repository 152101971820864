import { initializeApp } from "firebase/app";
import {getAuth,GoogleAuthProvider} from "firebase/auth";
import { getFirestore } from "firebase/firestore"
import { getStorage } from 'firebase/storage';


const firebaseConfig = {
  apiKey: "AIzaSyCoHC6f4IXGzFh5MNELf5tNntUBYlc3Vl8",
   authDomain: "sheksa-64d01.firebaseapp.com",
   projectId: "sheksa-64d01",
   storageBucket: "sheksa-64d01.appspot.com",
   messagingSenderId: "682872733261",
   appId: "1:682872733261:web:929f6454ed472fd8a46604"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth=getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);
const googleProvider = new GoogleAuthProvider();

export { db, auth, storage, googleProvider };