import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home } from "../pages/homePage"
import { About }  from "../pages/About";
import { Projects }  from "../pages/Projects";
import { Contact } from "../pages/Contact";
import  News   from "../pages/News";
import { AffiliateAgencies }  from "../pages/AffiliateAgencies";
import { Publicate }  from "../pages/Publicate";
import { NewsInfo }  from "../pages/NewsInfo";
import { Profile }  from "../pages/Profiles";
import { ForgotPassword }  from "../pages/ForgotPassword";
import { NewAgency }  from "../pages/NewAgency";
import { OwnAgencys }  from "../pages/OwnAgencys";
import { Details }  from "../pages/Details";
import { Login } from "../pages/login";
import { RegisterForm } from "../pages/Register";
import { UserProfile } from "../pages/UserProfile";
import { EmailVerification } from "../pages/EmailVerification";
import { Terms } from "../pages/Terms";


export function Rutas() {
    return (
        <>  
        <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>} />
                    <Route path="/Login" element={<Login />} />
                    <Route path="/Register" element={<RegisterForm />} />
                    <Route path="/About" element={<About />} />
                    <Route path="/Projects" element={<Projects />} />
                    <Route path="/Contact" element={<Contact />} />
                    <Route path="/News" element={<News />} />
                    <Route path="/AffiliateAgencies" element={<AffiliateAgencies />} />
                    <Route path="/Publicate" element={<Publicate />} />
                    <Route path="/NewsInfo" element={<NewsInfo />} />
                    <Route path="/Profile" element={<Profile />} />
                    <Route path="/ForgotPassword" element={<ForgotPassword />} />
                    <Route path="/NewAgency" element={<NewAgency />} />
                    <Route path="/OwnAgencys" element={<OwnAgencys />} />
                    <Route path="/Projects/:id" element={<Details collection= "Projects" />} />
                    <Route path="/News/:id" element={<Details collection= "News"/>} />  
                    <Route path="/Agencies/:id" element={<Details collection= "Agencies"/>} />
                    <Route path="/UserProfile/:userId" element={<UserProfile />} />
                    {/*<Route path="/Pay" element={<PaymentPage />} />*/}
                    <Route path="/EmailVerification" element={<EmailVerification />} />
                    <Route path="/Terms" element={<Terms />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}