import React, { useState } from 'react';
import { db, auth, storage } from '../firebase/config';
import { addDoc, collection, updateDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import Modal from 'react-modal';
import styles from '../assets/styles/Modal.module.css';
import estadosMunicipios from './Location';

const Publicate = ({ collectionName, fields, modalIsOpen, closeModal }) => {
  const [formData, setFormData] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [additionalImages, setAdditionalImages] = useState([]);
  const [selectedState, setSelectedState] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleAdditionalImagesChange = (e) => {
    const files = Array.from(e.target.files);
    if (files.length + additionalImages.length > 10) {
      alert('No puedes subir más de 10 imágenes adicionales.');
      return;
    }
    setAdditionalImages([...additionalImages, ...files]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = auth.currentUser;

    if (!user || !user.uid) {
      console.error('Usuario no autenticado o UID no disponible');
      return;
    }

    try {
      let image  = '';
      if (imageFile) {
        const storageRef = ref(storage, `${collectionName}/portada/${imageFile.name}`);
        const snapshot = await uploadBytes(storageRef, imageFile);
        image  = await getDownloadURL(snapshot.ref);
      }

      const additionalImagesUrls = [];
      for (const file of additionalImages) {
        const storageRef = ref(storage, `${collectionName}/imagenes/${file.name}`);
        const snapshot = await uploadBytes(storageRef, file);
        const url = await getDownloadURL(snapshot.ref);
        additionalImagesUrls.push(url);
      }

      const docRef = await addDoc(collection(db, collectionName), {
        ...formData,
        userId: user.uid,
        userName: user.displayName,
        image: image,
        additionalImages: additionalImagesUrls,
        createdAt: serverTimestamp(),
      });

      console.log('Documento creado correctamente: ', docRef.id);
      await updateDoc(docRef, { id: docRef.id });

      setFormData({});
      setImageFile(null);
      setAdditionalImages([]);
      closeModal();
      window.location.reload();
    } catch (err) {
      console.error('Error al guardar en Firestore: ', err);
    }
  };

  const handleChangeState = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setSelectedCity('');
    setFormData({
      ...formData,
      state: state,
      city: '',
    });
  };

  const handleChangeCity = (e) => {
    const city = e.target.value;
    setSelectedCity(city);
    setFormData({
      ...formData,
      city: city,
    });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel={`Nuevo ${collectionName}`}
      className={styles.modal}
      overlayClassName={styles.modalOverlay}
    >
      <div>
        <h2>Nuevo {collectionName}</h2>
        <form onSubmit={handleSubmit}>
          {fields.map((field) => (
            <div key={field.name} className={styles['form-group']}>
              <label htmlFor={field.name}>{field.label}</label>
              
              {field.type === 'select' && field.name === 'state' && (
                <select
                  className={styles['form-control']}
                  id={field.name}
                  name={field.name}
                  value={selectedState}
                  onChange={handleChangeState}
                  required={field.required}
                >
                  <option value="">Selecciona un estado</option>
                  {Object.keys(estadosMunicipios).map((estado) => (
                    <option key={estado} value={estado}>
                      {estado}
                    </option>
                  ))}
                </select>
              )}

              {field.type === 'select' && field.name === 'city' && (
                <select
                  className={styles['form-control']}
                  id={field.name}
                  name={field.name}
                  value={selectedCity}
                  onChange={handleChangeCity}
                  required={field.required}
                  disabled={!selectedState}
                >
                  <option value="">Selecciona una ciudad</option>
                  {selectedState &&
                    estadosMunicipios[selectedState].map((city) => (
                      <option key={city} value={city}>
                        {city}
                      </option>
                    ))}
                </select>
              )}

              {field.type === 'select' && field.name === 'category' && (
                <select
                  className={styles['form-control']}
                  id={field.name}
                  name={field.name}
                  value={formData[field.name] || ''}
                  onChange={handleChange}
                  required={field.required}
                >
                  {field.options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              )}

              {field.type !== 'select' && field.name !== 'state' && field.name !== 'city' && (
                <input
                  type={field.type}
                  className={styles['form-control']}
                  id={field.name}
                  name={field.name}
                  value={formData[field.name] || ''}
                  onChange={handleChange}
                  required={field.required}
                />
              )}
            </div>
          ))}
          <div className={styles['form-group']}>
            <label htmlFor="coverImage">Imagen de Portada</label>
            <input
              type="file"
              className={styles['form-control']}
              id="coverImage"
              name="coverImage"
              accept="image/*"
              onChange={handleImageChange}
              required
            />
          </div>
          <div className={styles['form-group']}>
            <label htmlFor="additionalImages">Imágenes Adicionales</label>
            <input
              type="file"
              className={styles['form-control']}
              id="additionalImages"
              name="additionalImages"
              accept="image/*"
              multiple
              onChange={handleAdditionalImagesChange}
            />
          </div>

          <button type="submit" className={styles['btn btn-primary']}>
            Guardar
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default Publicate;