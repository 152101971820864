import React from 'react';
import Modal from 'react-modal';
import styles from "../assets/styles/Profiles.module.css";

Modal.setAppElement('#root');

const EditProfileModal = ({ isOpen, onRequestClose, userDetails, newName, setNewName, newEmail, setNewEmail, newPhone, setNewPhone, newPhoto, setNewPhoto, handleSaveProfile }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Edit Profile"
      className={styles.modalContent}
      overlayClassName={styles.modalOverlay}
    >
      <div className={styles.formE}>
        <h2>Editar Perfil</h2>
        <div className={styles.contentI}>
          <p>Nombre de usuario</p>
          <input
            type="text"
            className={styles.formControl}
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            placeholder="Nuevo nombre"
          />
        </div>
          
        <div className={styles.contentI}>
          <p>Correo electrónico</p>
          <input
            type="email"
            className={styles.formControl}
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
            placeholder="Nuevo correo"
          />
        </div>

        <div className={styles.contentI}>
          <p>Número de teléfono</p>
          <input
            type="text"
            className={styles.formControl}
            value={newPhone}
            onChange={(e) => setNewPhone(e.target.value)}
            placeholder="Nuevo teléfono"
          />
        </div>

        <div className={styles.contentI}>
          <p>Foto de perfil</p>
          <input
            type="file"
            className={`${styles.formControlI} ${styles.fileInput}`}
            onChange={(e) => setNewPhoto(e.target.files[0])}
          />
        </div>
        
        <div className={styles.contentI}>
          <button className={`${styles.btn1}`} onClick={handleSaveProfile}>
            Guardar
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default EditProfileModal;
