import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from '../assets/styles/Publicate.module.css';
import { Navbar } from '../components/navbar1';
import { Footer } from '../components/Footer';
import PaymentButton from '../components/LinkPay'; // Asegúrate de ajustar la ruta al archivo de PaymentButton

export function Publicate() {
  const { t } = useTranslation('Publicate');

  const BASIC_PLAN = 'basic';
  const PLUS_PLAN = 'plus';
  const BASIC_AMOUNT = 30;
  const PLUS_AMOUNT = 500;


  return (
    <div>
      <div className={styles.headerContainer}>
        <div className={styles.navBa}>
          <Navbar />
        </div>
        <div className={styles.overlay}></div>
        <div className={styles.headerContent}>
          <h1>{t('header')}</h1>
        </div>
      </div>
      <div className={styles.plancontainer}>
        <div className={styles.planheader}>
          <h2>{t('choosePlan')}</h2>
        </div>
        <div className={styles.plans}>
          <div className={styles.plan}>
            <h3>{t('basicSubscription')}</h3>
            <p className={styles.planprice}>{t('basicPrice')}</p>
            <p>{t('basicDescription')}</p>
            <ul>
              <li>✅ {t('basicFeature1')}</li>
              <li>✅ {t('basicFeature2')}</li>
              <li>✅ {t('basicFeature3')}</li>
              <li>✅ {t('basicFeature4')}</li>
              <li>✅ {t('basicFeature5')}</li>
            </ul>
            <div className={styles.planbutton} >
              <PaymentButton amount={BASIC_AMOUNT} planType={BASIC_PLAN} />
            </div>
          </div>
          <div className={styles.plan}>
            <h3>{t('plusSubscription')}</h3>
            <p className={styles.planprice}>{t('plusPrice')}</p>
            <p>{t('plusDescription')}</p>
            <ul>
              <li>✅ {t('plusFeature1')}</li>
              <li>✅ {t('plusFeature2')}</li>
              <li>✅ {t('plusFeature3')}</li>
              <li>✅ {t('plusFeature4')}</li>
              <li>✅ {t('plusFeature5')}</li>
            </ul>
            <div className={styles.planbutton} >
              <PaymentButton amount={PLUS_AMOUNT} planType={PLUS_PLAN}/>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}