import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { db } from "../firebase/config";
import { doc, getDoc } from "firebase/firestore";
import styles from "../assets/styles/Profiles.module.css";
import { Navbar } from '../components/navbar1';
import pedirObjetos from '../components/pedirObjetos';
import ItemList from '../components/ItemList';

export function UserProfile() {
  const { userId } = useParams();
  const [userDetails, setUserDetails] = useState(null);
  const [objetos, setObjetos] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDocRef = doc(db, "Users", userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserDetails(userDocSnap.data());
        } else {
          console.error("Usuario no encontrado");
        }
      } catch (error) {
        console.error("Error al obtener los datos del usuario: ", error);
      }
    };

    fetchUserData();
  }, [userId]);

  useEffect(() => {
    const fetchUserObjects = async () => {
      try {
        const objetos = await pedirObjetos("Agencies", userId);
        setObjetos(objetos);
      } catch (error) {
        setObjetos([]);
        console.error("Error al obtener los objetos: ", error);
      }
    };

    fetchUserObjects();
  }, [userId]);

  const displayFields = {
    image: true,
    title: true,
    description: false,
    city: true,
    state: false,
    direction: false,
    category: false,
    price: true,
    userName: false,
  };

  return (
    <div className={styles.aaa}>   
      <div className={styles.container}>
        <div className={styles.navBa}>
          <Navbar />
        </div>
        {userDetails ? (
          <>
            <div className={styles.profileHeader}>
              <div className={styles.imgHeader}>
                <img
                  src={userDetails.photo}
                  className={styles.profileImg}
                  alt="User Profile"
                />
              </div>
              <div className={styles.infHeader}>
                <h3 className={styles.profileName}>{userDetails.Name}</h3>
                <p className={styles.profileEmail}>{userDetails.email}</p>
                {userDetails.phone && <p className={styles.profilePhone}>{userDetails.phone}</p>}
              </div>
            </div>
          </>
        ) : (
          <p>Cargando...</p>
        )}
      </div>
      <div className={styles.Prop}>
        <div className={styles.linea}></div>
        <div className={styles.textP}>
          <h2>Propiedades de {userDetails?.Name}</h2>
        </div>
        <div>
          <ItemList objetos={objetos} displayFields={displayFields} collectionName="Agencies" />
        </div>
      </div>
    </div>
  );
}