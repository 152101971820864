import { db } from "../firebase/config"
import { collection, getDocs, query, where } from "firebase/firestore";


//import data from "../data/data.json"

const pedirObjetos = async (collectionName, userId = null) => {
  try {
    const objetos = [];
    const q = userId ? query(collection(db, collectionName), where("userId","==",userId)) : collection(db, collectionName);
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      objetos.push({
        id: doc.id,
        ...doc.data()
      });
    });

    return objetos;
  }
  catch (error) {
    throw new Error("Error al obtener objetos: "+error.message);
  }
}

export default pedirObjetos;