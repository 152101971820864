import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar } from '../components/navbar1';
import { Footer } from '../components/Footer';
import { CardInfHome } from '../components/cardInfHome';
import Slider from 'react-slick';
import styles from '../assets/styles/home.module.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { InfoExtra } from '../components/InfoExtra';
import { collection, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase/config';
import { Toolbar } from '../components/Toolbar';
import { useTranslation } from 'react-i18next';

export function Home() {
  const { t } = useTranslation('Home');
  const navigate = useNavigate();
  const [projects, setProjects] = useState([]);
  const [news, setNews] = useState([]);

  const goToContact = () => {
    navigate('/Contact');
  };

  const goToProjects = () => {
    navigate('/Projects');
  };

  useEffect(() => {
    const fetchData = async () => {
      const projectsQuery = query(collection(db, 'Projects'), orderBy('createdAt', 'desc'), limit(5));
      const projectsSnapshot = await getDocs(projectsQuery);
      const projects = projectsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setProjects(projects);

      const newsQuery = query(collection(db, 'News'), orderBy('createdAt', 'desc'), limit(5));
      const newsSnapshot = await getDocs(newsQuery);
      const news = newsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setNews(news);
    };

    fetchData();
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: projects.length > 1 ? 2 : 1, // Muestra uno si hay un solo proyecto, de lo contrario muestra tres
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 1, // Muestra uno si hay un solo proyecto, de lo contrario muestra dos
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1, // Solo muestra una tarjeta // Muestra uno si hay un solo proyecto, de lo contrario muestra dos
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Solo muestra una tarjeta
          slidesToScroll: 1,
          centerMode: true, // Para centrar la tarjeta actual
          centerPadding: '5px' // Ajusta el espacio alrededor de la tarjeta actual
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div>
      <div className={styles.headerContainer}>
        <div className={styles.containerIdioma}>
          <div className={styles.containerIdioma1}>
            <Toolbar />
          </div>
        </div>
        <div className={styles.navBa}>
          <Navbar />
        </div>
        
        <div className={styles.overlay}></div>
        <div className={styles.headerContent}>
          <h1>{t('headerTitle')}</h1>
          <h2>{t('headerSubtitle')}</h2>
        </div>
      </div>

      <div className={styles.headerButtons}>
        <h3>{t('headerButtonsTitle')}</h3>
        <p>{t('headerButtonsSubtitle')}</p>
        <div className={styles.buttons}>
          <button className={styles.contactButton} onClick={goToContact}>{t('contactUs')}</button>
          <button className={styles.projectsButton} onClick={goToProjects}>{t('viewProjects')}</button>
        </div>
      </div>
      
      <div className={styles.aboutSection}>
        <div className={styles.aboutContent}>
          <h2>{t('aboutUs')}</h2>
          <p>{t('aboutUsDescription')}</p>
        </div>
        <div className={styles.aboutImage}>
          <img src="sk1.jpg" alt="Imagen de la empresa" />
        </div>
      </div>

      <div className={styles.logosSection}>
        <h2>{t('newsTitle')}</h2>
      </div>

      <div className={styles.carouselSection}>
        {projects.length > 0 && (
          <Slider {...settings}>
            {projects.map((project, index) => (
              <div key={index} className={styles.projectsWrapper}>
                <CardInfHome
                  title={project.title}
                  subtitle={project.subtitle}
                  description={project.description}
                  image={project.image}
                />
              </div>
            ))}
          </Slider>
        )}
      </div>

      <div className={styles.sectionHeader}>
        <h2>{t('directionsTitle')}</h2>
      </div>

      <div className={styles.mapSection}>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7489.48792708038!2d-100.14998111083092!3d20.186383300000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d31b0593130dc7%3A0x355ffeca0b223b4b!2sAMEALCO%20CENTRO!5e0!3m2!1ses-419!2smx!4v1721184875576!5m2!1ses-419!2smx"
          width="1220"
          height="450"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Ubicación de Sheksa Construcciones">
        </iframe>
      </div>

      <div className={styles.sectionHeader}>
        <h2>{t('newsHeader')}</h2>
      </div>

      <div className={styles.cardsHome}>
        {news.map((newsItem, index) => (
          <InfoExtra
            key={index}
            title={newsItem.title}
            subtitle={newsItem.subtitle}
            description={newsItem.description}
            image={newsItem.image}
          />
        ))}
      </div>

      <Footer />
    </div>
  );
}
