import { Navbar } from '../components/navbar1';
import { Footer } from '../components/Footer';
import styles from '../assets/styles/About.module.css';
import { useTranslation } from 'react-i18next';

export function About() {
    const { t } = useTranslation('About');

    return (
        <div>
            <div className={styles.headerContainer}>
                <div className={styles.navBa}>
                    <Navbar />
                </div>
                <div className={styles.overlay}></div>
                <div className={styles.headerContent}>
                    <h1>{t('header.title')}</h1>
                </div>
            </div>
            
            <div className={styles.banner}>
                <h3>{t('whoWeAre.title')}</h3>
            </div>
            <div className={styles.about}>
                <div className={styles['about-container1']}>
                    <p>{t('whoWeAre.description')}</p>
                </div>
            </div>
            <div className={styles.banner}>
                <h3>{t('businessFocus.title')}</h3>
            </div>

            <div className={styles.about}>
                <div className={styles.containerUno}>
                    <div className={styles.bloques}>
                        <div className={styles.misionPP}>
                            <h4>{t('mission.heading')}</h4>  
                        </div>
                        <div className={styles.tresAbajo}>
                            <div className={styles['about-container']}>
                                <p>{t('mission.text1')}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.bloques}>
                        <div className={styles.misionPP}>
                            <h4>{t('vision.heading')}</h4>  
                        </div>
                        <div className={styles.tresAbajo}>
                            <div className={styles['about-container']}>
                                <p>{t('mission.text2')}</p>
                            </div>
                        </div>
                    </div>
                    <div className={styles.bloques}>
                        <div className={styles.misionPP}>
                            <h4>{t('COMPROMISO.heading')}</h4>  
                        </div>
                        <div className={styles.tresAbajo}>
                            <div className={styles['about-container']}>
                                <p>{t('mission.text3')}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.banner}>
                <h3>{t('clients.title')}</h3>
            </div>
            <div className={styles['grid-container']}>
                <div><img src='coca-cola.png' alt='coca cola'/></div>
                <div><img src='home-depot.png' alt='home depot'/></div>
                <div><img src='mitsubishi.png' alt='mitsubishi'/></div>
                <div><img src='grupo-marti.png' alt='grupo marti'/></div>
                <div><img src='wtc.png' alt='world trade center'/></div>
                <div><img src='kimberly.png' alt='Kimberly'/></div>
            </div>
            <Footer />
        </div>
    )
}
