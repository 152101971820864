import React from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from './statusUser'; // Ajusta la ruta si es necesario
import styles from "../assets/styles/btnPay.module.css"

const PaymentButton = ({ amount, planType }) => {
  const navigate = useNavigate();
  const { user } = useAuth(); // Obtén el usuario autenticado de Firebase

  const handlePayment = async () => {
    if (!user) {
      navigate('/login');
      return;
    }

    try {
      const userId = user.uid; // ID del usuario de Firebase
      const description = planType === 'BASIC' ? 'Suscripción BASIC' : 'Suscripción PLUS';
      const response = await axios.post('https://api-gw.payclip.com/checkout', {
        amount: amount,
        currency: 'MXN',
        purchase_description: description, // Incluye la descripción
        redirection_url: {
          success: 'https://sheksaconstrucciones.com.mx',
          error: 'https://my-website.com/redirection/error',
          default: 'https://my-website.com/redirection/default',
        },
        metadata: {
          me_reference_id: userId,
          customer_info: {
          },
        },
      }, {
        headers: {
          'accept': 'application/vnd.com.payclip.v2+json',
          'content-type': 'application/json',
          'x-api-key': 'Basic ODZjOTkzMzYtMTVkYy00MTVmLWJiNjctZDU4NWM5YzBiZjhiOmI3YmNlNGNhLTUxMzEtNDFmNS04YzliLWIwNmJjZjI5YThlOA==',
        },
      });

      const { payment_request_url, payment_request_id } = response.data;

      // Almacenar el payment_request_id, purchase_description y el tipo de suscripción para futuras consultas
      localStorage.setItem('paymentRequestId', payment_request_id);
      localStorage.setItem('purchaseDescription', description); // Almacenar la descripción
      localStorage.setItem('typeSubscription', planType);

      window.location.href = payment_request_url;
    } catch (error) {
    }
  };

  return <button onClick={handlePayment} className={styles.btnP}>Pagar</button>;
};

export default PaymentButton;
