import { Navbar } from '../components/navbar1';
import { Footer } from '../components/Footer';
import { useEffect, useState } from 'react';
import pedirObjetos from '../components/pedirObjetos';
import ItemList from '../components/ItemList';
import styles from "../assets/styles/News.module.css";
import Publicate from '../components/Publications';
import { useAuth } from '../components/statusUser';
import { useTranslation } from 'react-i18next';

const News = () => {
   const [modalIsOpen, setModalIsOpen] = useState(false);
   const [objetos, setObjetos] = useState([]);
   const { isAdmin } = useAuth();
   const { t } = useTranslation('news'); // Usar 'news' como namespace para traducciones

   const openModal = () => setModalIsOpen(true);
   const closeModal = () => setModalIsOpen(false);

   useEffect(() => {
      const collectionName = "News";
      pedirObjetos(collectionName)
         .then((res) => {
            setObjetos(res);
         })
         .catch((err) => {
            setObjetos([]);
         })
   }, [])

   const displayFields = {
      image: true,
      title: true,
      description: false,
      city: false,
      state: false,
      direction: false,
      category: false,
      price: false
   }

   const collectionName = "News";

   return (
      <div>
         <div className={styles.headerContainer}>
            <div className={styles.navBa}>
               <Navbar />
            </div>
            <div className={styles.overlay}></div>
            <div className={styles.headerContent}>
               <h1>{t('novelties')}</h1>
            </div>
         </div>
         
         {isAdmin && (
            <button onClick={openModal} className="btn btn-primary">
               {t('add_new_project')}
            </button>
         )}
         <ItemList objetos={objetos} displayFields={displayFields} collectionName={collectionName}/>
         <Publicate
            collectionName="News"
            fields={[
               { name: 'title', label: t('title'), type: 'text', required: true },
               { name: 'description', label: t('description'), type: 'text', required: true },
               // Agrega más campos según sea necesario
            ]}
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
         />
         <Footer />
      </div>
   )
}

export default News;
