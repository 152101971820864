import React from 'react';
import styles from '../assets/styles/Terms.module.css'; // Asegúrate de tener este archivo CSS para el estilo

export function Terms (){
  return (
    <div className={styles.termsContainer}>
      <div className={styles.termsBox}>
        <h1>Términos y Condiciones</h1>

        <section>
          <h2>1. Introducción</h2>
          <p>
            Bienvenido a Sheksa. Al utilizar nuestro sitio web, aceptas estos términos y condiciones. Por favor, léelos detenidamente.
          </p>
        </section>

        <section>
          <h2>2. Requisitos de Registro</h2>
          <p>
            Para acceder a ciertas funciones de nuestro sitio, debes registrarte y proporcionar información precisa y completa. Eres responsable de mantener la confidencialidad de tu cuenta y contraseña.
          </p>
        </section>

        <section>
          <h2>3. Uso del Servicio</h2>
          <p>
            Puedes utilizar nuestro sitio solo para fines legales y de acuerdo con estos términos. Está prohibido usar el sitio para actividades ilegales o no autorizadas.
          </p>
        </section>

        <section>
          <h2>4. Propiedad Intelectual</h2>
          <p>
            Todo el contenido y materiales en nuestro sitio son propiedad de [Nombre de tu Empresa] y están protegidos por derechos de autor y otras leyes de propiedad intelectual.
          </p>
        </section>

        <section>
          <h2>5. Política de Privacidad</h2>
          <p>
            Tu privacidad es importante para nosotros. Consulta nuestra <a href="/PrivacyPolicy">Política de Privacidad</a> para más detalles sobre cómo recopilamos y utilizamos tu información personal.
          </p>
        </section>

        <section>
          <h2>6. Modificaciones</h2>
          <p>
            Podemos actualizar estos términos en cualquier momento. Te notificaremos sobre cualquier cambio y deberás aceptar los nuevos términos para continuar utilizando el sitio.
          </p>
        </section>

        <section>
          <h2>7. Terminación</h2>
          <p>
            Nos reservamos el derecho de cancelar tu cuenta y acceso al sitio si incumples estos términos.
          </p>
        </section>

        <section>
          <h2>8. Limitación de Responsabilidad</h2>
          <p>
            No seremos responsables de ningún daño o pérdida derivada del uso de nuestro sitio.
          </p>
        </section>

        <section>
          <h2>9. Resolución de Disputas</h2>
          <p>
            Estos términos se rigen por las leyes de [Jurisdicción]. Cualquier disputa se resolverá en [Lugar de Resolución].
          </p>
        </section>

        <section>
          <h2>10. Contacto</h2>
          <p>
            Si tienes alguna pregunta sobre estos términos, por favor contáctanos en [Tu Email de Contacto].
          </p>
        </section>
      </div>
    </div>
  );
};
