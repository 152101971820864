import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import styles from '../assets/styles/infoExtra.module.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Importa los estilos del carrusel

export function Details({ collection }) {
    const { id } = useParams();
    const [details, setDetails] = useState(null);

    useEffect(() => {
        const fetchDetails = async () => {
            console.log('Fetching details...');
            try {
                const docRef = doc(db, collection, id);
                const docSnap = await getDoc(docRef);
                console.log('Document fetched:', docSnap.exists(), docSnap.data());
                if (docSnap.exists()) {
                    setDetails(docSnap.data());
                } else {
                    console.log(`No se encontró el documento en la colección ${collection}.`);
                }
            } catch (error) {
                console.error(`Error al obtener detalles de ${collection}:`, error);
            }
        };
    
        fetchDetails();
    }, [collection, id]);

    if (!details) {
        console.log('Loading details...');
        return <p>Cargando...</p>;
    }

    console.log('Detalles:', details);

    // Define los campos a mostrar dinámicamente
    const displayFields = {
        Projects: [
            { key: 'title', label: 'Título' },
            { key: 'price', label: 'Precio' },
            { key: 'city', label: 'Ciudad' },
            { key: 'state', label: 'Estado' },
            { key: 'category', label: 'Categoria' },
            { key: 'description', label: 'Descripción' },
            { key: 'userName', label: 'Empresa' },
            // Agrega más campos específicos de Projects según sea necesario
        ],
        News: [
            { key: 'title', label: 'Título' },
            { key: 'description', label: 'Descripcion' },
            // Agrega más campos específicos de News según sea necesario
        ],
        Agencies: [
            { key: 'title', label: 'Título' },
            { key: 'price', label: 'Precio' },
            { key: 'category', label: 'Categoria' },
            { key: 'city', label: 'Ciudad' },
            { key: 'state', label: 'Estado' },
            { key: 'description', label: 'Descripcion' },
            { key: 'userName', label: 'De: ' },
            // Agrega más campos específicos de Agencies según sea necesario
        ]
    };

    const images = details.additionalImages ? [details.image, ...details.additionalImages] : [details.image];
    console.log('Imágenes:', images);
    console.log('Esta es la coleccion', collection);
    console.log('Campos a mostrar:', displayFields[collection]); // Verifica los campos a mostrar
    

    return (
        <div className={styles.containerAI}>
            <div className={styles.card}>
                <div className={styles.cardImage}>
                    <Carousel showThumbs={false} dynamicHeight={true}>
                        {images.map((img, index) => (
                            <div key={index}>
                                <img src={img || '/sk1.jpg'} alt={`Imagen ${index}`} />
                            </div>
                        ))}
                    </Carousel>
                </div>
                <div className={styles.cardContent}>
                    <h2 className={styles.title}>Detalles</h2>
                    {displayFields[collection]?.map(field => (
                        <p key={field.key} className={styles.description}>
                            {field.label}: {details[field.key] || 'N/A'}
                        </p>
                    ))}
                </div>
            </div>
        </div>
    );
}
